import React from "react";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import MUIDataTable from "mui-datatables";
import TextField from '@material-ui/core/TextField';
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const { DateTime } = require("luxon");

class ClicksToSalesHighLowSpikesAlarms extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();

        this.state = {
            startDate: DateTime.utc().minus({ weeks: 1 }).startOf('day'),
            endDate: DateTime.utc().endOf('day'),
            minDate: "1753-01-01",
            maxDate: "9999-12-31",
            dateError: false,
            alarms: [],
            alarmsLoading: false,
            showResolved: false,
            RedirectFailuresTable: {
                title: "Clicks To Sales Spikes",
                options: {
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                        <div className="d-flex flex-row align-items-center mx-1">
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={() => this.confirmResolved(selectedRows, displayData)}>
                                        Resolve Selected URLs
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                    selectableRows: "multiple",
                    sortOrder: {
                        name: 'userId',
                        direction: 'asc'
                    },
                },
                getTheme: () => createTheme({
                    overrides: {
                        MUIDataTableBodyRow: {
                            root: {
                                '&:nth-child(odd)': {
                                    backgroundColor: '#F9F9F9'
                                },
                                cursor: 'pointer'
                            }
                        },
                    }
                })
            },
        };
    }

    componentDidMount = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
        this.getAlarms();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
            if (this.state.startDate?.isValid && this.state.endDate?.isValid && this.state.startDate < this.state.endDate) {
                this.setState({ dateError: false });
                await this.getAlarms();
            } else {
                this.setState({ dateError: true });
            }
        }
    };

    confirmResolved = (selectedRows, displayData) => {
        const confirmDelete = window.confirm("Are you sure you want to RESOLVE these items");
        if (confirmDelete) {
            this.handleResolved(selectedRows, displayData);
        }
    }

    handleResolved = async (selectedRows, displayData) => {
        //this.setState({ loading: true });

        const selectedRowsData = selectedRows.data;
        const alarmIds = selectedRowsData.map(row => displayData[row.index].data[0]);
        const deleteUrl = "Products/PostAlarmHistoryResolved";

        const payload = {
            alarmIds
        }

        //setTimeout(() => {
        //    this.tableRef.current.setState({ selectedRows: { data: [] } });
        //}, 3000);

        const success = await postRequest(deleteUrl, payload);

        if (success) {
            await this.getAlarms();
            this.tableRef.current.setState({ selectedRows: { data: [] } }); // Deselects the table rows using this.tableRef created with React.createRef()

            this.setState({ deleteError: false });
        } else {
            this.setState({ deleteError: true });
        }

        this.setState({ loading: false });
    }


    handleStartDateChange = (event) => {
        const startDateRaw = event.target.value;
        if (startDateRaw.length === 0) return;

        const startDate = DateTime.fromISO(startDateRaw).toUTC().startOf('day');
        if (startDate.isValid) {
            this.setState({ startDate });
        } else {
            console.error("Invalid start date entered:", startDateRaw);
        }
    };

    handleEndDateChange = (event) => {
        const endDateRaw = event.target.value;
        if (endDateRaw.length === 0) return;

        const endDate = DateTime.fromISO(endDateRaw).toUTC().endOf('day');
        if (endDate.isValid) {
            this.setState({ endDate });
        } else {
            console.error("Invalid end date entered:", endDateRaw);
        }
    };

    handleWeekToDate = () => {
        const startDate = DateTime.utc().minus({ weeks: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().endOf('day') });
    };

    handleMonthToDate = () => {
        const startDate = DateTime.utc().minus({ months: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().endOf('day') });
    };

    handleYearToDate = () => {
        const startDate = DateTime.utc().minus({ years: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().endOf('day') });
    };

    handleShowResolvedChange = (event) => {
        const showResolved = event?.target?.checked ?? false; // Safely get the checked value or default to false

        this.setState({ showResolved, alarms: [] }, () => this.getAlarms());
    };

    getAlarms = async () => {
        let url = `Products/GetAlarmClicksToSalesHighLowSpikes`;
        const { startDate, endDate, showResolved } = this.state;
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            url += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            url += url.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }
        url += `&showAll=${showResolved}`;

        url += `&offset=${offset}`

        this.setState({ alarmsLoading: true });

        try {
            const alarms = await getRequest(url);

            this.setState({ alarms, alarmsLoading: false });
        } catch (error) {
            console.error("Error fetching alarms:", error);
            this.setState({ alarms: [], alarmsLoading: false });
        }
    };

    render() {
        const columns = [
            {
                name: "alarmId",
                label: "Alarm ID",
                options: {
                    filter: true,
                    display: false,
                },
            },
            {
                name: "creatorUserId",
                label: "Creator UserId",
                options: {
                    filter: true,
                    display: false,
                },
            },
            {
                name: "alarmCreatedDate",
                label: "Alarm Date",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value) => {
                        if (!value) {
                            return "-";
                        }
                        try {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        } catch (error) {
                            console.error("Invalid date encountered:", value, error);
                            return "-";
                        }
                    },
                },
            },
            {
                name: "creatorUserName",
                label: "Creator",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "totalClicks",
                label: "Clicks",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "totalSales",
                label: "Sales",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "clicksToSalesRatio",
                label: "Clicks To Sales Ratio",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value) => {
                        if (!value) {
                            return "N/A";
                        }
                        try {
                            return value.toFixed(2)
                        } catch (error) {
                            console.error("Invalid date encountered:", value, error);
                            return "-";
                        }
                    }
                },
            },
            {
                name: "averageClicksToSalesRatio",
                label: "RockPorch Average Ratio",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value) => {
                        if (!value) {
                            return "N/A";
                        }
                        try {
                            return value.toFixed(2)
                        } catch (error) {
                            console.error("Invalid date encountered:", value, error);
                            return "-";
                        }
                    }
                },
            },
            {
                name: "spikeType",
                label: "Lower/Higher Spike",
                options: {
                    filter: true,
                    sort: true,
                },
            },
        ];

        return (
            <div style={{ display: this.props.display ? "" : "none" }}>
                <section className="gray-bg mb-0 pt-2">
                    <div className="pt-0 pb-0 px-5">
                        <div
                            className={`mx-0 d-flex justify-content-between pb-4 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : "pt-4"
                                } mx-auto`}
                        >
                            <div
                                className={`d-flex ${["sm"].includes(this.props.deviceSize) ? "w-100 pt-3 mx-2" : ""
                                    } justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "start"}`}
                                style={{ gap: ["sm"].includes(this.props.deviceSize) ? "" : "30px", fontFamily: "'Archivo',sans-serif" }}
                            >
                                <div className="d-flex flex-column">
                                    <label className="mb-0" style={{ cursor: "pointer" }}>
                                        <input
                                            type="checkbox"
                                            checked={this.state.showResolved}
                                            onChange={this.handleShowResolvedChange}
                                            style={{ marginRight: "8px" }}
                                        />
                                        Resolved Alarms
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg mb-0 pt-0">
                    {
                        !["sm"].includes(this.props.deviceSize) ?
                            <></>
                            :
                            <div className="d-flex w-100 justify-content-end pr-2" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>1y</div>
                            </div>
                    }
                    <p className="ml-5 pt-3 mb-0" style={{ color: "red", display: this.state.dateError ? "" : "none" }}>Start Date must be before the End Date</p>

                    <div className="pt-0 pb-0 px-5">

                        <div className={`mx-0 d-flex justify-content-between pb-4 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : "pt-0"} mx-auto`}>

                            <div className={`d-flex ${["sm"].includes(this.props.deviceSize) ? "w-100 pt-3 mx-2" : ""} justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "start"}`} style={{ gap: ["sm"].includes(this.props.deviceSize) ? "" : "30px", fontFamily: "'Archivo',sans-serif" }}>

                                <div className="d-flex flex-column">
                                    <label className="mb-0">Start Date:</label>
                                    <TextField
                                        id="start-date"
                                        type="date"
                                        value={this.state.startDate.toISODate()}
                                        onChange={this.handleStartDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            style: { fontFamily: "'Archivo', sans-serif" },
                                        }} />
                                </div>
                                <div className="d-flex flex-column">
                                    <label className="mb-0">End Date:</label>
                                    <TextField
                                        id="end-date"
                                        type="date"
                                        value={this.state.endDate.toISODate()}
                                        onChange={this.handleEndDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            style: { fontFamily: "'Archivo', sans-serif" },
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                ["sm"].includes(this.props.deviceSize) ?
                                    <></>
                                    :
                                    <div className="d-flex" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                        <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                        <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                        <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>1y</div>
                                    </div>
                            }

                        </div>
                    </div>

                </section>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.RedirectFailuresTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.RedirectFailuresTable.title}
                                        data={this.state.alarms}
                                        columns={columns}
                                        options={this.state.RedirectFailuresTable.options}
                                        ref={this.tableRef}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ClicksToSalesHighLowSpikesAlarms;
